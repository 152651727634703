<template>
  <v-card class="shadow-sm">
    <v-card-text>
    <template v-if="!hasStore">

      <h5 class="ps-6 mt-6">{{ store.entidad }}</h5>

      <v-list dense>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon small color="grey darken-1">mdi-map-marker-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t('Footer.contact.address') }}</v-list-item-title>
            <v-list-item-subtitle>{{ store.domicilio }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-avatar>
            <v-icon small color="grey darken-1">mdi-email-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t('Footer.contact.email') }}</v-list-item-title>
            <v-list-item-subtitle>{{ store.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-avatar>
            <v-icon small color="grey darken-1">mdi-phone-outline</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $t('Footer.contact.phone') }}</v-list-item-title>
            <v-list-item-subtitle>{{ store.telefono }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <h5 class="ps-6 mt-6">{{ $t('Panel.shipment.title') }}</h5>

    <div class="mx-6 text-center">
      <v-switch disabled :label="$t('Panel.shipment.free')" hide-details class="text-caption"></v-switch>
    </div>

    <h5 class="ps-6 mt-6">{{ $t('Panel.price.order') }}</h5>

    <div class="mx-6 text-center">
      <v-checkbox
        v-model="internalValue.sortBy"
        :label="$t('Panel.price.max')"
        color="primary"
        value="desc"
        hide-details
      ></v-checkbox>

      <v-checkbox
        v-model="internalValue.sortBy"
        :label="$t('Panel.price.min')"
        color="primary"
        value="asc"
        hide-details
      ></v-checkbox>
    </div>

    <h5 class="ps-6 mt-6">{{ $t('Panel.product.categories') }}</h5>

    <template v-if="isLoadingCategories">
      <v-skeleton-loader type="list-item" v-for="i in 8" :key="`sk-${i}`"></v-skeleton-loader>
    </template>

    <template v-else>
      <v-treeview
        v-model="internalValue.categories"
        :items="categories"
        item-key="grpProductoId"
        item-text="grpProducto"
        selectable
        dense
        open-on-click
        class="mx-4 my-4"
      >
        <template #label="{ item }">
          <div>{{ item.grpProducto }}</div>
        </template>
      </v-treeview>
    </template>

    <div class="mx-6 pt-3">
      <h5 class="">{{$t('Panel.price.title')}} {{ `$ ${parseInt(internalValue.precioUnitario[0]).toFixed(2)} - $ ${parseInt(internalValue.precioUnitario[1]).toFixed(2)}` }}</h5>

      <v-range-slider v-model="internalValue.precioUnitario" class="mt-10" :min="priceMin" :max="priceMax" tick-size="4" >

        <template v-slot:thumb-label="props">
          {{ props.value }}
        </template>

      </v-range-slider>
    </div>

    <div class="mx-6 pt-3">
      <v-text-field v-model="priceMin" outlined :label="$t('Panel.price.minRange')" prefix="$" dense @change="(v) => updatePrice(v, 0)"></v-text-field>

      <v-text-field v-model="priceMax" outlined :label="$t('Panel.price.maxRange')" prefix="$" dense @change="(v) => updatePrice(v, 1)"></v-text-field>
    </div>

    <h5 class="ps-6 mt-3">{{ $t('Panel.product.popularity') }}</h5>

    <div class="mx-6 pt-3 pb-4">
      <v-rating color="primary" background-color="primary" hover length="5" :value="5" disabled></v-rating>
    </div>
  </v-card-text>
</v-card>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    entidadId: {
      type: String,
      default: null,
      required: true
    },
    value: {}
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
    hasStore(){
      return _.isEmpty(this.store);
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  data() {
    return {
      store: {},
      categories: [],
      priceRange: [0, 10000],
      priceMax: 10000,
      priceMin: 0,
      isLoadingStore: false,
      isLoadingCategories: false
    }
  },
  mounted() {
    //this.fetchStore();

    this.fetchCategories();
  },
  methods: {
    updatePrice(v, i){
      this.internalValue.precioUnitario[i] = v
    },
    fetchStore() {

      this.isLoadingStore = true;

      // SE REALIZA PETICION PARA OBTENER INFORMACION DE LA TIENDA DESDE API
      axios
        .get(`api/v1/public/entities/${this.entidadId}`)
        .then((res) => {

          // SE OBTIENE LA INFORMACION DE LA TIENDA DE MANERA SEGURA
          const store = _.get(res, "data.data", []);

          // SE ESTABLECE LA INFORMACION DE LA TIENDA A VARIABLE REACTIVA
          this.store = store;
        })
        .catch(() => {

          this.store = {};
        })
        .finally(() => {
          this.isLoadingStore = false;
        });
    },
    fetchCategories(){

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/entities/${this.entidadId}/categories`;
      } else {
        url += `/public/entities/${this.entidadId}/categories`;
      }

      // SE DEFINE FUNCION PARA GENERAR ARBOL DE CATEGORIAS
      function transformToTree(arr) {
        var nodes = {};
        return arr.filter(function (obj) {
          var id = obj["grpProductoId"],
            parentId = obj["grpProductoPadreID"];

          nodes[id] = _.defaults(obj, nodes[id], { children: [] });
          parentId && (nodes[parentId] = (nodes[parentId] || { children: [] }))["children"].push(obj);

          return !parentId;
        });
      }

      this.isLoadingCategories = true;

      // SE REALIZA PETICION PARA OBTENER LAS CATEGORIAS DE PRODUCTOS DE LA TIENDA DESDE API
      axios.get(url)
        .then((res) => {

          // SE OBTIENEN LAS CATEGORIAS DE MANERA SEGURA
          const categories = _.get(res, 'data.data');
          
          // SE GENERA EL ARBOL DE CATEGORIAS
          const categoriesTree = transformToTree(categories);

          // SE ESTABLECE EL ARBOL DE CATEGORIAS A VARIABLE REACTIVA
          this.categories = categoriesTree;

        })
        .catch(() => {
          
          this.categories = [];
        })
        .finally(() => {
          this.isLoadingCategories = false;
        });
    },
  }
}
</script>